<template>
  <div
    v-if="Object.keys(profileData).length"
    id="user-profile"
  >
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <!-- about suggested page and twitter feed -->
        <b-col
          lg="6"
          cols="12"
          order="2"
          order-lg="1"
        >
          <profile-about :about-data="profileData" />
        </b-col>
        <!--/ about suggested page and twitter feed -->
        <!-- post -->
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import AppUtils from '@/auth/jwt/useJwt'

import ProfileAbout from './ProfileAbout.vue'

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    ProfileAbout,
  },
  data() {
    return {
      profileData: { },
    }
  },
  created() {
    AppUtils.getUserProfile().then(res => { this.profileData = res.data })
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
